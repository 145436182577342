import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const HeaderContent = (props) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    document.getElementById("overlay").onclick = function () {
      setShow(!show);
    };
  });

  return (
    <header className={`main-header ${show ? "active" : ""}`}>
      <div id="overlay" className="animate__animated animate__fadeIn"></div>
      <nav className="navbar navbar-expand-lg navbar-dark">
        <div className="container">
          <Link
            to="/"
            className="navbar-brand logo animate__animated animate__fadeInDown"
          ></Link>
          <button
            className="navbar-toggler"
            onClick={() => {
              setShow(!show);
            }}
            type="button"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className={`collapse ${
              show ? "show" : ""
            } navbar-collapse animate__animated animate__fadeIn`}
            id="navbarNav"
          >
            <ul className="navbar-nav">
              <li className={`nav-item nav-item-logo`}>
                <Link to="/" className="navbar-link">
                  <img src="/assets/img/logo/logo-white.svg" alt="Homepage" />
                </Link>
              </li>
              <li
                className={`nav-item ${
                  props.page === "launchpad" ? "active" : ""
                }`}
              >
                <Link to="/launchpad" className="navbar-link">
                  Launchpad
                </Link>
              </li>
              <li
                className={`nav-item ${
                  props.page === "projects" ? "active" : ""
                }`}
              >
                <Link to="/projects" className="navbar-link">
                  Projects
                </Link>
              </li>
              <li
                className={`nav-item ${props.page === "nft" ? "active" : ""}`}
              >
                <Link to="/nft" className="navbar-link">
                  NFT
                </Link>
              </li>
              <li className="nav-item d-md-none apply-btn">
                <Link
                  to="/apply"
                  className="btn btn-primary btn-md"
                  data-ripple="rgba(0, 0, 0, 0.15)"
                >
                  Apply
                </Link>
              </li>
            </ul>
          </div>

          <ul className="cta animate__animated animate__fadeInDown">
            <li>
              <Link
                to="/apply"
                className="btn btn-primary btn-md"
                data-ripple="rgba(0, 0, 0, 0.15)"
              >
                Apply
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default HeaderContent;
