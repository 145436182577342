import axios from "axios";
import { API_BASE_URL, JWT_TOKEN } from "config/AppConfig";

const service = axios.create({
  baseURL: API_BASE_URL,
  timeout: 60000,
});

service.interceptors.request.use(
  (config) => {
    config.headers["Authorization"] = `Bearer ${JWT_TOKEN}`;
    return config;
  },
  (error) => {
    console.error(error);
    Promise.reject(error);
  }
);

export default service;
