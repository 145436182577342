import React from "react";
import { nanoid } from "nanoid";

import ProjectItem from "./project-item";

const ProjectSection = (props) => {
  return (
    <>
      {props.data.map((element) => {
        return (
          <div className="col-12 col-md-6" key={nanoid()}>
            <ProjectItem data={element} isLive={props.isLive} />
          </div>
        );
      })}
    </>
  );
};

export default ProjectSection;
