/* eslint-disable react/jsx-no-target-blank */
import React from "react";

const Doxxed = (props) => {
  return (
    <>
      <img src="/assets/img/site/doxxed.svg" alt="Doxxed" /> Doxxed&nbsp;
    </>
  );
};
const Verified = (props) => {
  return (
    <>
      <img src="/assets/img/site/verified.svg" alt="Verified" /> Verified
    </>
  );
};

const mintDateTime = (date, time) => {
  if (date != null) {
    const month = new Date(date)
      .toLocaleString("en-US", { month: "short" })
      .toUpperCase();
    let dateSuffix = "";
    switch (parseInt(date.split("-")[2])) {
      case 1:
        dateSuffix = "ST";
        break;

      case 2:
        dateSuffix = "ND";
        break;

      case 3:
        dateSuffix = "RD";
        break;

      default:
        dateSuffix = "TH";
        break;
    }
    let datetimeString = `${month} ${parseInt(
      date.split("-")[2]
    )}${dateSuffix} — `;

    if (time != null) {
      datetimeString += time.split(":")[0] + ":" + time.split(":")[1] + " UTC";
    } else {
      datetimeString += "TBA";
    }
    return datetimeString;
  } else {
    return "TBA";
  }
};

const ProjectItem = (props) => {
  const data = props.data.attributes;

  return (
    <div className="collection-item">
      <div className="featured-img">
        <img src={data.image.data.attributes.url} alt={data.name} />
        <span className="badges">
          {data.doxxed ? <Doxxed /> : <></>}
          {data.verified ? <Verified /> : <></>}
        </span>
        <span className="sol-price">{data.price} ◎</span>
        <div className="link-overlay">
          <div className="btn-group" role="group">
            {data.discord ? (
              <a
                href={data.discord}
                target="_blank"
                className="btn btn-outline-secondary"
              >
                <i className="fa-brands fa-discord"></i>
              </a>
            ) : (
              <></>
            )}
            {data.twitter ? (
              <a
                href={data.twitter}
                target="_blank"
                className="btn btn-outline-secondary"
              >
                <i className="fa-brands fa-twitter"></i>
              </a>
            ) : (
              <></>
            )}
            {data.website ? (
              <a
                href={data.website}
                target="_blank"
                className="btn btn-outline-secondary"
              >
                <i className="fa-solid fa-link"></i>
              </a>
            ) : (
              <></>
            )}
          </div>
          {props.isLive && data.mintSite ? (
            <div className="btn-group" role="group">
              <a href={data.mintSite} target="_blank" className="mint-button">
                Mint
              </a>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <h3>{data.name}</h3>
      <span className="badge badge-dark">{data.supply} Supply</span>
      <span className="badge badge-dark">
        {mintDateTime(data.mintDate, data.mintTime)}
      </span>
    </div>
  );
};

export default ProjectItem;
