import React from "react";
import { Widget } from "@typeform/embed-react";
import AOS from "aos";
import "aos/dist/aos.css";
import Layout from "layout";

class Apply extends React.Component {
  render() {
    return (
      <Layout page="apply">
        <section className={"block intro animate__animated animate__fadeIn"}>
          <div className={"container"}>
            <Widget
              id="h2mtxblZ"
              className="typeform-wrapper"
              inlineOnMobile={true}
              medium={"snippet"}
            />
          </div>
        </section>
      </Layout>
    );
  }

  componentDidMount() {
    document.body.classList.add("page-index");
    AOS.init({
      easing: "easeOutQuad",
      once: true,
    });
  }

  componentWillUnmount() {
    document.body.classList.remove("page-index");
  }
}

export default Apply;
