import React from "react";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { nanoid } from "nanoid";
import qs from "qs";

import fetch from "utils/FetchInterceptor";

import "swiper/css";
import "swiper/css/pagination";

const Testimonial = (props) => {
  return (
    <div>
      <h1 className="quote">{props.review}</h1>
      <div className="card mb-3 mt-3">
        <div className="row g-0">
          <div className="col-5 offset-4">
            <img
              src={props.avatar.data.attributes.url}
              className="img-fluid circle"
              alt={`${props.projectName} NFT`}
            />
          </div>
          <div className="col-13 d-flex align-items-center">
            <div className="card-body">
              <h5 className="card-title">{props.projectName}</h5>
              <p className="card-text">
                <small>{props.clientName}</small>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

class Testimonials extends React.Component {
  constructor(props) {
    super(props);
    this.state = { testimonials: [] };
  }

  render() {
    return (
      <section
        className="block testimonials"
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        <div className="container-full">
          <Swiper
            spaceBetween={30}
            slidesPerView={"auto"}
            centeredSlides={true}
            pagination={{ dynamicBullets: true, clickable: true }}
            grabCursor={true}
            modules={[Pagination]}
          >
            {this.state.testimonials.map((element) => {
              return (
                <SwiperSlide key={nanoid()}>
                  <Testimonial {...element.attributes} />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </section>
    );
  }

  componentDidMount() {
    const query = qs.stringify({
      sort: ["updatedAt:desc"],
      populate: "*",
      pagination: {
        page: 1,
        pageSize: 100,
      },
    });
    fetch({
      url: `/testimonials?${query}`,
      method: "get",
    })
      .then((response) => this.setState({ testimonials: response.data.data }))
      .catch((error) => {
        console.error(error.message);
        this.setState([]);
      });
  }

  componentWillUnmount() {}
}

export default Testimonials;
