import React from "react";
import { Link } from "react-router-dom";

const FooterCta = (props) => {
  return (
    <section className="footer-cta">
      <div className="container">
        <div className="cta-box" data-ripple="">
          <Link to="/apply" className="link">
            <h4>Apply</h4>
            <h1>Start your project</h1>
            <div className="right">
              <div className="arrow"></div>
            </div>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default FooterCta;
