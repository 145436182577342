/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { Link } from "react-router-dom";
import qs from "qs";
import AOS from "aos";
import "aos/dist/aos.css";
import fetch from "utils/FetchInterceptor";
import Layout from "layout";
import Testimonials from "views/projects/testimonials";
import ProjectList from "views/projects/project-list";

class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      liveProjects: [],
      upcomingProjects: [],
      pastProjects: [],
    };
  }

  render() {
    return (
      <Layout page="index">
        <section className="block intro animate__animated animate__fadeIn">
          <div className="container">
            <div className="row">
              <div className="col-sm-14">
                <h1>Make money while you sleep.</h1>
                <p className="lead mt-2 mb-4">
                  Easy-to-use revenue sharing launchpad on Solana. We share 60%
                  of everything we make with holders of our Launchlabs NFT.
                </p>
                <Link
                  to="/nft"
                  className="btn btn-primary btn-md mt-2"
                  data-ripple="rgba(0, 0, 0, 0.2)"
                >
                  Learn about our NFT
                </Link>
              </div>
            </div>
          </div>
          <video
            playsInline
            autoPlay
            muted
            loop
            poster="/assets/img/site/video-poster.png"
            className="background-video"
            id="background-video"
          >
            <source
              src="/assets/video/v2/launchlabs-pass.webm"
              type="video/webm"
            />
            <source
              src="/assets/video/v2/launchlabs-pass.mp4"
              type="video/mp4"
            />
            <source
              src="/assets/video/v2/launchlabs-pass.mov"
              type="video/mov"
            />
          </video>
        </section>
        <section
          className="block launchpad"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <div className="container">
            <div className="box-wrapper">
              <div className="row">
                <div className="col d-flex justify-content-between">
                  <h4 className="tag">The Launchpad</h4>
                  <div>
                    <span className="badge badge-dark">Closed beta</span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <h1>
                    <span>Create and launch your NFT.</span> No code required.
                  </h1>
                  <p className="lead">
                    Create and launch your NFT in three simple steps. Select
                    mint settings, generate images and upload your logo/assets
                    for the mint site. That´s it!
                  </p>
                  <Link
                    to="/launchpad"
                    className="btn btn-primary btn-md mt-4"
                    data-ripple="rgba(0, 0, 0, 0.2)"
                  >
                    Learn more
                  </Link>
                </div>
              </div>
              <div className="preview">
                <img src="/assets/img/site/launchpad-preview.png" alt="" />
              </div>
            </div>
          </div>
        </section>

        <section
          className="block nft"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <div className="container">
            <div className="box-wrapper">
              <div className="row">
                <div className="col">
                  <h4 className="tag">The NFT</h4>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <h1>
                    <span>Earn passive income.</span> Launchlabs Pass.
                  </h1>
                  <p className="lead">
                    Holders of our NFTs get 60% of everything we make. We have
                    split our NFTs into different tiers. Holding a platinum pass
                    pays more than a bronze, etc.
                  </p>

                  <p className="lead">
                    By doing this, we are effectively a community-owned
                    launchpad. We think our model is pretty smart, since a
                    strong community is key in any good project.
                  </p>
                  <Link
                    to="/nft"
                    className="btn btn-primary btn-md mt-4"
                    data-ripple="rgba(0, 0, 0, 0.2)"
                  >
                    Learn more
                  </Link>
                </div>
              </div>
              <video
                playsInline
                autoPlay
                muted
                loop
                poster="/assets/img/site/video-poster.png"
                className="background-video"
                id="background-video"
              >
                <source
                  src="/assets/video/v1/video-1-2.webm"
                  type="video/webm"
                />
                <source src="/assets/video/v1/video-1-2.mp4" type="video/mp4" />
                <source src="/assets/video/v1/video-1-2.mov" type="video/mov" />
              </video>
            </div>

            <div className="blob">
              <div className="circle circle1"></div>
              <div className="circle circle2"></div>
              <div className="circle circle3"></div>
              <div className="circle circle4"></div>
              <div className="circle circle5"></div>
            </div>
          </div>
        </section>

        <div id="reviews"></div>

        <Testimonials />

        <ProjectList
          upcomingProjects={this.state.upcomingProjects}
          liveProjects={this.state.liveProjects}
          pastProjects={this.state.pastProjects}
        />

        <section
          className="block radrugs"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <div className="container">
            <div className="box-wrapper">
              <div className="row">
                <div className="col-md-12">
                  <img
                    src="/assets/img/site/radrugs.gif"
                    alt="Radrugs"
                    style={{ width: "100%" }}
                  />
                </div>
                <div className="col-md-12 content">
                  <div className="row">
                    <div className="col d-flex justify-content-between">
                      <h4 className="tag">Your safe haven</h4>
                      <div>
                        <span className="badge badge-dark">Partnership</span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <h1>
                        <span>Helps You DYOR</span> Radrugs audit.
                      </h1>
                      <p className="lead">
                        RadRugs is a NFT project on Solana that aims to
                        contribute to creating a safer and more inclusive
                        ecosystem for investors and to provide useful
                        information and analytics to help you DYOR.
                      </p>
                      <p>
                        We have partnered with RadRugs to review our launches at
                        no-cost (we effectively pay the fees).
                      </p>
                    </div>
                  </div>
                  <div>
                    <a
                      href="https://www.radrugs.io/"
                      target="_blank"
                      className="btn btn-primary btn-md mt-4"
                      data-ripple="rgba(0, 0, 0, 0.2)"
                    >
                      Go to Radrugs
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }

  componentDidMount() {
    document.body.classList.add("page-index");
    AOS.init({
      easing: "easeOutQuad",
      once: true,
    });

    const now = new Date();
    const currentDate = now.toISOString().split("T")[0];
    const currentTime = now.toISOString().split("T")[1].split(".")[0];

    const upcomingQuery = qs.stringify({
      sort: ["mintDate:asc", "mintTime:asc"],
      populate: "*",
      filters: {
        mintOver: {
          $eq: false,
        },
        $or: [
          {
            mintDate: {
              $null: true,
            },
          },
          {
            mintDate: {
              $gt: currentDate,
            },
          },
          {
            mintDate: {
              $eq: currentDate,
            },
            $or: [
              {
                mintTime: {
                  $gt: currentTime,
                },
              },
              {
                mintTime: {
                  $null: true,
                },
              },
            ],
          },
        ],
      },
      pagination: {
        page: 1,
        pageSize: 100,
      },
    });
    const liveQuery = qs.stringify({
      sort: ["mintDate:desc", "mintTime:desc"],
      populate: "*",
      filters: {
        mintOver: {
          $eq: false,
        },
        $or: [
          {
            mintDate: {
              $lt: currentDate,
            },
          },
          {
            mintDate: {
              $eq: currentDate,
            },
            mintTime: {
              $lte: currentTime,
            },
          },
        ],
      },
      pagination: {
        page: 1,
        pageSize: 100,
      },
    });
    const pastQuery = qs.stringify({
      sort: ["mintDate:desc", "mintTime:desc"],
      populate: "*",
      filters: {
        mintOver: {
          $eq: true,
        },
      },
      pagination: {
        page: 1,
        pageSize: 100,
      },
    });

    // Fetch upcoming projects
    fetch({
      url: `/projects?${upcomingQuery}`,
      method: "get",
    })
      .then((response) =>
        this.setState({ upcomingProjects: response.data.data })
      )
      .catch((error) => {
        console.error(error.message);
        this.setState({ upcomingProjects: [] });
      });

    // Fetch live projects
    fetch({
      url: `/projects?${liveQuery}`,
      method: "get",
    })
      .then((response) => this.setState({ liveProjects: response.data.data }))
      .catch((error) => {
        console.error(error.message);
        this.setState({ liveProjects: [] });
      });

    // Fetch past projects
    fetch({
      url: `/projects?${pastQuery}`,
      method: "get",
    })
      .then((response) => this.setState({ pastProjects: response.data.data }))
      .catch((error) => {
        console.error(error.message);
        this.setState({ pastProjects: [] });
      });
  }

  componentWillUnmount() {
    document.body.classList.remove("page-index");
  }
}

export default HomePage;
