import { Routes, Route } from "react-router-dom";

import ScrollToTop from "components/util-components/scroll-to-top";
import HomePage from "views/homepage";
import Launchpad from "views/launchpad";
import Projects from "views/projects";
import Nft from "views/nft";
import Apply from "views/apply";

function App() {
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="launchpad" element={<Launchpad />} />
        <Route path="projects" element={<Projects />} />
        <Route path="nft" element={<Nft />} />
        <Route path="apply" element={<Apply />} />
      </Routes>
    </>
  );
}

export default App;
