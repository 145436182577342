/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Layout from "layout";
import Calculator from "./calculator";

class Nft extends React.Component {
  render() {
    return (
      <Layout page="nft">
        <section className="block intro animate__animated animate__fadeIn">
          <div className="container-full">
            <div className="row">
              <div className="col-sm-9 offset-md-2 d-flex justify-content-center flex-column">
                <div className="px-4 mb-4 mb-sm-0 px-sm-0">
                  <h1>Launchlabs Pass</h1>
                  <p className="lead mt-2 mb-4">
                    Earn passive income. We share 60% of everything we make with
                    holders of our passes. Think of them as shares that gets
                    paid dividends.
                  </p>
                  <a
                    href="https://magiceden.io/marketplace/launchlabs"
                    target="_blank"
                    className="btn btn-secondary btn-md mt-2"
                    data-ripple="rgba(0, 0, 0, 0.2)"
                  >
                    Buy on Magic Eden <i className="me-icon"></i>
                  </a>
                </div>
              </div>
              <div className="col-sm-12 offset-md-1">
                <img
                  src="/assets/img/site/nft-cards-hero.png"
                  alt=""
                  className="hero-cards animate__animated animate__fadeInUp img-fluid"
                />
              </div>
            </div>
          </div>
        </section>

        <section className="block nft-cards" id="nfts">
          <div className="container">
            <div
              className="row mt-5 headline-box mb-5 pb-5"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <div className="col-24 col-sm-10 px-4 px-sm-0 mb-4 mb-sm-0">
                <h2>Tiers and details</h2>
                <img
                  src="/assets/img/site/card-list.png"
                  alt=""
                  style={{ width: "200px" }}
                  className=""
                />
              </div>
              <div className="col-24 col-sm-14 px-4 px-sm-0">
                <p className="lead">
                  We are utilizing a revenue-sharing model with holders of our
                  NFT. We share 60% of everything we make. We are onboarding new
                  projects to our beta on a regular basis. We believe in the
                  power of community, holders will get whitelists for all
                  projects launched with Launchlabs.
                </p>
              </div>
            </div>
            <div
              className="row bordered"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <div className="col-24 mt-5 mt-sm-0 col-sm-11">
                <img
                  src="/assets/img/nft/bronze-card.png"
                  alt=""
                  className="img-fluid nft-pass"
                />
              </div>
              <div className="col-24 mt-5 mt-sm-0 col-sm-12 d-flex justify-content-center flex-column">
                <h2>TIER 01: Bronze</h2>

                <table className="table">
                  <tr>
                    <td>
                      <strong>Supply:</strong>
                    </td>
                    <td>800/2000</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Revenue share:</strong>
                    </td>
                    <td>30% of revenue pool</td>
                  </tr>
                </table>
              </div>
            </div>

            <div
              className="row bordered"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <div className="col-24 mt-5 mt-sm-0 col-sm-11">
                <img
                  src="/assets/img/nft/silver-card.png"
                  alt=""
                  className="img-fluid nft-pass"
                />
              </div>
              <div className="col-24 mt-5 mt-sm-0 col-sm-12 d-flex justify-content-center flex-column">
                <h2>TIER 02: Silver</h2>

                <table className="table">
                  <tr>
                    <td>
                      <strong>Supply:</strong>
                    </td>
                    <td>560/2000</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Revenue share:</strong>
                    </td>
                    <td>60% of revenue pool split with gold and platinum</td>
                  </tr>
                </table>
              </div>
            </div>

            <div
              className="row bordered"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <div className="col-24 mt-5 mt-sm-0 col-sm-11">
                <img
                  src="/assets/img/nft/gold-card.png"
                  alt=""
                  className="img-fluid nft-pass"
                />
              </div>
              <div className="col-24 mt-5 mt-sm-0 col-sm-12 d-flex justify-content-center flex-column">
                <h2>TIER 02: Gold</h2>

                <table className="table">
                  <tr>
                    <td>
                      <strong>Supply:</strong>
                    </td>
                    <td>300/2000</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Revenue share:</strong>
                    </td>
                    <td>60% of revenue pool split with silver and platinum</td>
                  </tr>
                </table>
              </div>
            </div>

            <div
              className="row bordered"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <div className="col-24 mt-5 mt-sm-0 col-sm-11">
                <img
                  src="/assets/img/nft/platinum-card.png"
                  alt=""
                  className="img-fluid nft-pass"
                />
              </div>
              <div className="col-24 mt-5 mt-sm-0 col-sm-12 d-flex justify-content-center flex-column">
                <h2>TIER 02: Platinum</h2>

                <table className="table">
                  <tr>
                    <td>
                      <strong>Supply:</strong>
                    </td>
                    <td>200/2000</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Revenue share:</strong>
                    </td>
                    <td>60% of revenue pool split with silver and gold</td>
                  </tr>
                </table>
              </div>
            </div>

            <div
              className="row bordered"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <div className="col-24 mt-5 mt-sm-0 col-sm-11">
                <img
                  src="/assets/img/nft/solana-card.png"
                  alt=""
                  className="img-fluid nft-pass"
                />
              </div>
              <div className="col-24 mt-5 mt-sm-0 col-sm-12 d-flex justify-content-center flex-column">
                <h2>TIER 03: Solana</h2>

                <table className="table">
                  <tr>
                    <td>
                      <strong>Supply:</strong>
                    </td>
                    <td>135</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Revenue share:</strong>
                    </td>
                    <td>10% of total shared with Black</td>
                  </tr>
                </table>
              </div>
            </div>

            <div
              className="row bordered"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <div className="col-24 mt-5 mt-sm-0 col-sm-11">
                <img
                  src="/assets/img/nft/black-card.png"
                  alt=""
                  className="img-fluid nft-pass"
                />
              </div>
              <div className="col-24 mt-5 mt-sm-0 col-sm-12 d-flex justify-content-center flex-column">
                <h2>TIER (?): Black</h2>

                <table className="table">
                  <tr>
                    <td>
                      <strong>Supply:</strong>
                    </td>
                    <td>5</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Revenue share:</strong>
                    </td>
                    <td>
                      10% of total shared with Solana + 0.1% of the total
                      revenue taken from our cut.
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </section>

        <Calculator />

        <section className="block mint-details" id="mint-details">
          <div className="container">
            <div className="row">
              <div
                className="col-24 mb-4 mt-3 mt-sm-0 aos-init aos-animate"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <h3>
                  Our total supply is 2000 NFTs minted in 3 batches. <br />
                  <span className="text-secondary">
                    Currently there is 1954 passes in circulation.
                  </span>
                </h3>
              </div>
              <div className="col-24 col-md-8">
                <div
                  className="box-bordered regular mb-4 aos-init aos-animate"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="100"
                >
                  <div className="d-flex align-items-center">
                    <div className="ml-4" style={{ width: "100%" }}>
                      <div className="d-flex justify-content-between">
                        <div className="badge bg-primary mb-3">FIRST MINT</div>
                        <h4>1.5 ◎</h4>
                      </div>
                      <h3>655 passes minted</h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-24 col-md-8">
                <div
                  className="box-bordered regular mb-4 aos-init aos-animate"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="200"
                >
                  <div className="d-flex align-items-center">
                    <div className="ml-4" style={{ width: "100%" }}>
                      <div className="d-flex justify-content-between">
                        <div className="badge bg-primary mb-3">SECOND MINT</div>
                        <h4>5 ◎</h4>
                      </div>
                      <h3>747 passes minted</h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-24 col-md-8">
                <div
                  className="box-bordered regular aos-init aos-animate"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="300"
                >
                  <div className="d-flex align-items-center">
                    <div className="ml-4" style={{ width: "100%" }}>
                      <div className="d-flex justify-content-between">
                        <div className="badge bg-primary mb-3">THIRD MINT</div>
                        <h4>6 ◎</h4>
                      </div>
                      <h3>598 passes minted</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          className="nft-3d"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="300"
        >
          <a
            href="https://magiceden.io/marketplace/launchlabs"
            target="_blank"
            className="btn btn-secondary btn-md mt-2"
            data-ripple="rgba(0, 0, 0, 0.2)"
          >
            Buy on Magic Eden <i className="me-icon"></i>
          </a>
        </section>

        <section className="block dao-wallet">
          <div className="container"></div>
        </section>
      </Layout>
    );
  }

  componentDidMount() {
    document.body.classList.add("page-nft");
    AOS.init({
      easing: "easeOutQuad",
      once: true,
    });
  }

  componentWillUnmount() {
    document.body.classList.remove("page-nft");
  }
}

export default Nft;
