import React from "react";
import qs from "qs";
import AOS from "aos";
import "aos/dist/aos.css";
import fetch from "utils/FetchInterceptor";
import Layout from "layout";
import FeaturedItem from "./featured-item";
import Testimonials from "./testimonials";
import ProjectSection from "./project-section";

class Projects extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      liveProjects: [],
      upcomingProjects: [],
      pastProjects: [],
    };
  }

  render() {
    return (
      <Layout page="projects">
        <section className="block intro animate__animated animate__fadeIn">
          <div className="container">
            <div className="row">
              <div className="col-sm-14">
                <h1>Projects</h1>
                <p className="lead mt-2 mb-4" style={{ maxWidth: "30ch" }}>
                  We have currently launched{" "}
                  <span className="solana-text">
                    {this.state.liveProjects.length +
                      this.state.pastProjects.length}
                  </span>{" "}
                  projects, and have{" "}
                  <span className="solana-text">
                    {this.state.upcomingProjects.length}
                  </span>{" "}
                  projects launching soon.
                </p>
              </div>
            </div>
          </div>

          <div className="section section--featured">
            <div className="row-container">
              <div className="line">
                <div>
                  <div className="row">
                    <img
                      src="/assets/img/site/projects/line-bottom-min.png"
                      alt=""
                    />
                  </div>
                  <div className="row">
                    <img
                      src="/assets/img/site/projects/line-bottom-min.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>

              <div className="line second">
                <div>
                  <div className="row">
                    <img
                      src="/assets/img/site/projects/line-top-min.png"
                      alt=""
                    />
                  </div>
                  <div className="row">
                    <img
                      src="/assets/img/site/projects/line-top-min.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="line third">
                <div>
                  <div className="row">
                    <img
                      src="/assets/img/site/projects/line-bottom-min.png"
                      alt=""
                    />
                  </div>
                  <div className="row">
                    <img
                      src="/assets/img/site/projects/line-bottom-min.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="shadow">
              <span></span>
            </div>
          </div>
        </section>

        <section className="block project-list">
          <div className="container">
            <FeaturedItem />
          </div>
        </section>

        {this.state.liveProjects.length ? (
          <section className="block project-list">
            <div className="container">
              <div className="row">
                <h2 className="mb-5">Live</h2>
                <ProjectSection data={this.state.liveProjects} isLive={true} />
              </div>
            </div>
          </section>
        ) : (
          <></>
        )}
        {this.state.upcomingProjects.length ? (
          <section className="block project-list">
            <div className="container">
              <div className="row">
                <h2 className="mb-5">Upcoming</h2>
                <ProjectSection data={this.state.upcomingProjects} />
              </div>
            </div>
          </section>
        ) : (
          <></>
        )}
        {this.state.pastProjects.length ? (
          <section className="block project-list">
            <div className="container">
              <div className="row">
                <h2 className="mb-5">Past</h2>
                <ProjectSection data={this.state.pastProjects} />
              </div>
            </div>
          </section>
        ) : (
          <></>
        )}
        <Testimonials />
      </Layout>
    );
  }

  componentDidMount() {
    document.body.classList.add("page-index");
    AOS.init({
      easing: "easeOutQuad",
      once: true,
    });

    const now = new Date();
    const currentDate = now.toISOString().split("T")[0];
    const currentTime = now.toISOString().split("T")[1].split(".")[0];

    const upcomingQuery = qs.stringify({
      sort: ["mintDate:asc", "mintTime:asc"],
      populate: "*",
      filters: {
        mintOver: {
          $eq: false,
        },
        $or: [
          {
            mintDate: {
              $null: true,
            },
          },
          {
            mintDate: {
              $gt: currentDate,
            },
          },
          {
            mintDate: {
              $eq: currentDate,
            },
            $or: [
              {
                mintTime: {
                  $gt: currentTime,
                },
              },
              {
                mintTime: {
                  $null: true,
                },
              },
            ],
          },
        ],
      },
      pagination: {
        page: 1,
        pageSize: 100,
      },
    });
    const liveQuery = qs.stringify({
      sort: ["mintDate:desc", "mintTime:desc"],
      populate: "*",
      filters: {
        mintOver: {
          $eq: false,
        },
        $or: [
          {
            mintDate: {
              $lt: currentDate,
            },
          },
          {
            mintDate: {
              $eq: currentDate,
            },
            mintTime: {
              $lte: currentTime,
            },
          },
        ],
      },
      pagination: {
        page: 1,
        pageSize: 100,
      },
    });
    const pastQuery = qs.stringify({
      sort: ["mintDate:desc", "mintTime:desc"],
      populate: "*",
      filters: {
        mintOver: {
          $eq: true,
        },
      },
      pagination: {
        page: 1,
        pageSize: 100,
      },
    });

    // Fetch upcoming projects
    fetch({
      url: `/projects?${upcomingQuery}`,
      method: "get",
    })
      .then((response) =>
        this.setState({ upcomingProjects: response.data.data })
      )
      .catch((error) => {
        console.error(error.message);
        this.setState({ upcomingProjects: [] });
      });

    // Fetch live projects
    fetch({
      url: `/projects?${liveQuery}`,
      method: "get",
    })
      .then((response) => this.setState({ liveProjects: response.data.data }))
      .catch((error) => {
        console.error(error.message);
        this.setState({ liveProjects: [] });
      });

    // Fetch past projects
    fetch({
      url: `/projects?${pastQuery}`,
      method: "get",
    })
      .then((response) => this.setState({ pastProjects: response.data.data }))
      .catch((error) => {
        console.error(error.message);
        this.setState({ pastProjects: [] });
      });
  }

  componentWillUnmount() {
    document.body.classList.remove("page-index");
  }
}

export default Projects;
