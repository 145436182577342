import React from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Layout from "layout";

class Launchpad extends React.Component {
  render() {
    return (
      <Layout page="launchpad">
        <section className="block preview animate__animated animate__fadeIn">
          <div className="stripes animate__animated animate__fadeIn">
            <span
              className="animated animate__animated animate__fadeInRightBig"
              style={{ animationDelay: ".45s" }}
            ></span>
            <span
              className="animated animate__animated animate__fadeInRightBig"
              style={{ animationDelay: ".25s" }}
            ></span>
            <span
              className="animated animate__animated animate__fadeInLeftBig"
              style={{ animationDelay: ".7s" }}
            ></span>
            <span className="animated animate__animated animate__fadeInLeftBig"></span>
            <span
              className="animated animate__animated animate__fadeInLeftBig"
              style={{ animationDelay: ".24s" }}
            ></span>
          </div>
          <div className="container">
            <div className="row">
              <div className="col px-5 px-sm-0 col-md-14 offset-md-5 text-center">
                <h1>
                  Launch your <span className="solana-text">NFT project</span>{" "}
                  in three steps.
                </h1>
                <p className="lead mt-2 mb-4">
                  The easiest launchpad on Solana. 1. Configure metadata - 2.
                  Upload assets & generate images - 3. Configure your mint
                  site... and you're done!
                </p>
                <span className="badge badge-dark mb-4">Closed beta</span>
              </div>
            </div>
            <div className="video-container animate__animated animate__zoomInUp">
              <video
                playsInline
                autoPlay
                muted
                loop
                poster="/assets/img/site/video-poster.png"
                className="preview-video"
              >
                <source
                  src="/assets/video/demo-video-min.mp4"
                  type="video/mp4"
                />
              </video>
            </div>
          </div>
        </section>

        <section className="block launchpad">
          <div className="container">
            <div
              className="box-wrapper"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <div className="row">
                <div className="col text-center">
                  <h4 className="tag">The Launchpad</h4>
                  <h1 style={{ margin: "0 auto" }}>
                    Image generation has never been this smooth.{" "}
                    <strong>On any blockchain.</strong>
                  </h1>
                </div>
              </div>

              <img
                src="/assets/img/site/generation-preview.png"
                alt=""
                className="img-fluid"
              />
            </div>

            <div className="row">
              <div
                className="col-24 col-md-8"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="100"
              >
                <div className="feature-box mint-page">
                  <h3>
                    Custom mint page. <span>Tailored to your needs</span>
                  </h3>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                  <a href="#" className="btn btn-dblue btn-md mt-4">
                    View sample page{" "}
                    <i className="fa-solid fa-chevron-right ml-3"></i>
                  </a>
                </div>
              </div>
              <div
                className="col-24 col-md-8"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="200"
              >
                <div className="feature-box bot-feature">
                  <h3>
                    <span>No bots allowed.</span> It’s just you and your
                    community.
                  </h3>
                </div>
              </div>
              <div
                className="col-24 col-md-8"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="300"
              >
                <div className="feature-box wl-feature">
                  <h3>
                    Whitelist feature <span>Select who can mint.</span>
                  </h3>
                  <div className="spacer"></div>
                </div>
              </div>
            </div>

            <div className="row">
              <div
                className="col-24 col-md-12"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="400"
              >
                <div className="feature-box multi-upload">
                  <h3>
                    Multi-layer upload.{" "}
                    <span>Auto-naming and rarity rank.</span>
                  </h3>
                </div>
              </div>
              <div
                className="col-24 col-md-12"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="500"
              >
                <div className="feature-box airdrops">
                  <h4>Airdrop system</h4>
                  <h3>
                    <span>Airdrop anything. Just like that.</span>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div id="fees-discounts"></div>
        <section className="block fees-discounts">
          <div className="container">
            <div className="row">
              <div
                className="col-24 col-md-9"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div className="sticky-top pt-3 px-4 px-sm-0 mb-5">
                  <h2>Fees & Discounts</h2>
                  <p className="lead">
                    We utilize a burning mechanic for our clients to receive
                    discounts. This means our NFT collection is deflationary. To
                    receive the discount: you buy a pass on MagicEden and send
                    it back to us. We will then burn it and provide you a
                    discount.{" "}
                  </p>
                  <p className="lead">
                    We charge between 10-20% of the minting proceeds. We also
                    charge a 10% secondary royalty fee, meaning if the project
                    has 5% in royalties, our fee is 0.5% while the project keeps
                    4.5%
                  </p>
                </div>
              </div>
              <div className="col col-md-14 offset-md-1">
                <div
                  className="box-bordered regular mb-4"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="50"
                >
                  <div className="d-flex align-items-center">
                    <div className="ml-4">
                      <div className="badge bg-primary mb-3">
                        STARTING PRICE
                      </div>
                      <h3>20% of minting proceeds</h3>
                    </div>
                  </div>
                </div>

                <div
                  className="box-bordered mb-4"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="100"
                >
                  <div className="d-flex align-items-center">
                    <img src="/assets/img/site/bronze-card.png" alt="" />
                    <div className="ml-4">
                      <div className="badge bg-bronze mb-2 mr-2">TIER 1</div>{" "}
                      (3.33% per pass)
                      <h3>X3 = 10% fee</h3>
                    </div>
                  </div>
                </div>

                <div
                  className="box-bordered mb-4"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="150"
                >
                  <div className="d-flex r">
                    <div>
                      <img
                        src="/assets/img/site/silver-card.png"
                        alt=""
                        className="d-block mb-3"
                      />
                      <img
                        src="/assets/img/site/gold-card.png"
                        alt=""
                        className="d-block mb-3"
                      />
                      <img src="/assets/img/site/platinum-card.png" alt="" />
                    </div>
                    <div className="ml-4 mt-2">
                      <div className="badge bg-silver mb-2 mr-2">TIER 2</div>{" "}
                      (5% per pass)
                      <h3>X2 = 10% fee</h3>
                    </div>
                  </div>
                </div>

                <div
                  className="box-bordered"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="200"
                >
                  <div className="d-flex align-items-center">
                    <img src="/assets/img/site/solana-card.png" alt="" />
                    <div className="ml-4">
                      <div className="badge bg-solana mb-2">TIER 3</div>
                      <h3>X1 = 10% fee</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }

  componentDidMount() {
    document.body.classList.add("page-launchpad");
    AOS.init({
      easing: "easeOutQuad",
      once: true,
    });
  }

  componentWillUnmount() {
    document.body.classList.remove("page-launchpad");
  }
}

export default Launchpad;
