import React from "react";

import HeaderContent from "components/layout-components/header-content";
import FooterCta from "components/layout-components/footer-cta";
import Footer from "components/layout-components/footer";

class Layout extends React.Component {
  render() {
    return (
      <>
        <HeaderContent page={this.props.page} />
        <main>
          {this.props.children}
          {this.props.page === "apply" ? <></> : <FooterCta />}
        </main>

        <Footer page={this.props.page} />
      </>
    );
  }
}

export default Layout;
