/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { scrollWithOffset } from "utils/helper";

const Footer = (props) => {
  return (
    <footer className="footer" id="footer">
      <div className="container pb-50">
        <div className="row">
          <div className="col col-md-6">
            <img src="/assets/img/logo/logo-white.svg" alt="" />
          </div>
          <div className="col-24 col-md-6">
            <h4>SITE</h4>
            <ul className="navbar-nav">
              <li
                className={`nav-item ${
                  props.page === "launchpad" ? "active" : ""
                }`}
              >
                <Link to="/launchpad" className="navbar-link">
                  Launchpad
                </Link>
              </li>
              <li
                className={`nav-item ${
                  props.page === "projects" ? "active" : ""
                }`}
              >
                <Link to="/projects" className="navbar-link">
                  Projects
                </Link>
              </li>
              <li
                className={`nav-item ${props.page === "nft" ? "active" : ""}`}
              >
                <Link to="/nft" className="navbar-link">
                  NFT
                </Link>
              </li>
              <li className="nav-item">
                <HashLink
                  to="/nft#calculator"
                  scroll={(el) => scrollWithOffset(el, 100)}
                  className="navbar-link"
                >
                  Earnings Calculator
                </HashLink>
              </li>
              <li
                className={`nav-item ${
                  props.page === "careers" ? "active" : ""
                }`}
              >
                <span className="navbar-link">
                  <del>Careers</del>
                </span>
              </li>
            </ul>
          </div>
          <div className="col-24 col-md-6">
            <h4>CREATORS</h4>
            <ul className="navbar-nav">
              <li
                className={`nav-item ${props.page === "apply" ? "active" : ""}`}
              >
                <Link to="/apply" className="navbar-link">
                  Apply
                </Link>
              </li>
              <li className="nav-item">
                <HashLink
                  to="/launchpad#fees-discounts"
                  scroll={(el) => scrollWithOffset(el, 100)}
                  className="navbar-link"
                >
                  Fees & Discounts
                </HashLink>
              </li>
              <li className="nav-item">
                <HashLink
                  to="/#reviews"
                  scroll={(el) => scrollWithOffset(el, 100)}
                  className="navbar-link"
                >
                  Reviews
                </HashLink>
              </li>
            </ul>
          </div>
          <div className="col-24 col-md-6">
            <h4>JOIN US</h4>
            <ul className="navbar-nav">
              <li className="nav-item">
                <a
                  href="https://discord.gg/launchlabs"
                  className="navbar-link"
                  target="_blank"
                >
                  Discord
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="https://twitter.com/LaunchLabs_sol"
                  target="_blank"
                  className="navbar-link"
                >
                  Twitter
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="d-flex mt-5 text-center justify-content-center align-items-center">
        <p className="size-14">
          © Copyright 2022 Launchlabs. All rights reserved.{" "}
        </p>
      </div>
    </footer>
  );
};

export default Footer;
