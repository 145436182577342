/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import FeaturedItem from "./featured-item";
import ProjectSection from "./project-section";

const ProjectList = (props) => {
  useEffect(() => {
    if (document.getElementById("pills-tab").firstChild) {
      document.getElementById("pills-tab").firstChild.firstChild.click();
    }
  }, [props]);

  return (
    <section className="block project-list">
      <div className="container">
        <FeaturedItem />
        <ul
          className="nav nav-pills mb-3 mt-5"
          id="pills-tab"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          {props.liveProjects.length ? (
            <li className="nav-item">
              <a
                href="#"
                className="h2"
                id="pills-live-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-live"
                role="tab"
                aria-controls="pills-live"
                aria-selected="false"
              >
                Live
              </a>
            </li>
          ) : (
            <></>
          )}
          {props.upcomingProjects.length ? (
            <li className="nav-item">
              <a
                href="#"
                className="h2"
                id="pills-upcoming-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-upcoming"
                role="tab"
                aria-controls="pills-upcoming"
                aria-selected="false"
              >
                Upcoming
              </a>
            </li>
          ) : (
            <></>
          )}
          {props.pastProjects.length ? (
            <li className="nav-item">
              <a
                href="#"
                className="h2 "
                id="pills-past-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-past"
                role="tab"
                aria-controls="pills-past"
                aria-selected="false"
              >
                Past
              </a>
            </li>
          ) : (
            <></>
          )}
        </ul>
        <div
          className="tab-content"
          id="pills-tabContent"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          {props.liveProjects.length ? (
            <div
              className="tab-pane fade"
              id="pills-live"
              role="tabpanel"
              aria-labelledby="pills-live-tab"
            >
              <div className="row">
                <ProjectSection data={props.liveProjects} isLive={true} />
              </div>
            </div>
          ) : (
            <></>
          )}
          {props.upcomingProjects.length ? (
            <div
              className="tab-pane fade"
              id="pills-upcoming"
              role="tabpanel"
              aria-labelledby="pills-upcoming-tab"
            >
              <div className="row">
                <ProjectSection data={props.upcomingProjects} />
              </div>
            </div>
          ) : (
            <></>
          )}
          {props.pastProjects.length ? (
            <div
              className="tab-pane fade"
              id="pills-past"
              role="tabpanel"
              aria-labelledby="pills-past-tab"
            >
              <div className="row">
                <ProjectSection data={props.pastProjects} />
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </section>
  );
};

export default ProjectList;
