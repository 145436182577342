import React, { useState, useEffect } from "react";

const Calculator = (props) => {
  const parseValue = (param) => {
    const result = parseInt(param);
    if (result) {
      return result;
    } else {
      return 0;
    }
  };
  const supply = {
    bronze: 775,
    silver: 549,
    golden: 293,
    platinum: 199,
    solana: 134,
    black: 5,
  };
  const [balance, setBalance] = useState({
    bronze: 0,
    silver: 0,
    golden: 0,
    platinum: 0,
    solana: 0,
    black: 0,
  });
  const [community_income, setCommunityIncome] = useState(0);

  useEffect(() => {
    let result = 0;
    result += balance.bronze * ((community_income * 0.3) / supply.bronze);
    result +=
      (balance.silver + balance.golden + balance.platinum) *
      ((community_income * 0.6) /
        (supply.silver + supply.golden + supply.platinum));
    result +=
      (balance.solana + balance.black) *
      ((community_income * 0.1) / (supply.solana + supply.black));
    result += balance.black * (community_income / 0.6) * 0.001;
    document.getElementById("personal-income").value =
      parseFloat(result).toFixed(2);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [balance, community_income]);

  return (
    <section className="block calc" data-aos="fade-up" data-aos-duration="1000" id="calculator">
      <div className="container">
        <div className="box-wrapper">
          <div className="row">
            <div className="col">
              <h4>EARNINGS CALCULATOR</h4>
              <h1>
                Calculate your monthly <span>profits</span>
              </h1>
            </div>
          </div>
          <div className="calc-balance">
            <div className="calc-card">
              <div className="calc-input">
                <input
                  placeholder="0"
                  onChange={(e) => {
                    setBalance({
                      ...balance,
                      bronze: parseValue(e.target.value),
                    });
                  }}
                />
              </div>
              <div className="calc-image">
                <img src="/assets/img/nft/bronze-card.png" alt="bronze-card" />
              </div>
            </div>
            <div className="calc-card">
              <div className="calc-input">
                <input
                  placeholder="0"
                  onChange={(e) => {
                    setBalance({
                      ...balance,
                      silver: parseValue(e.target.value),
                    });
                  }}
                />
              </div>
              <div className="calc-image">
                <img src="/assets/img/nft/silver-card.png" alt="silver-card" />
              </div>
            </div>
            <div className="calc-card">
              <div className="calc-input">
                <input
                  placeholder="0"
                  onChange={(e) => {
                    setBalance({
                      ...balance,
                      golden: parseValue(e.target.value),
                    });
                  }}
                />
              </div>
              <div className="calc-image">
                <img src="/assets/img/nft/gold-card.png" alt="gold-card" />
              </div>
            </div>
            <div className="calc-card">
              <div className="calc-input">
                <input
                  placeholder="0"
                  onChange={(e) => {
                    setBalance({
                      ...balance,
                      platinum: parseValue(e.target.value),
                    });
                  }}
                />
              </div>
              <div className="calc-image">
                <img
                  src="/assets/img/nft/platinum-card.png"
                  alt="platinum-card"
                />
              </div>
            </div>
            <div className="calc-card">
              <div className="calc-input">
                <input
                  placeholder="0"
                  onChange={(e) => {
                    setBalance({
                      ...balance,
                      solana: parseValue(e.target.value),
                    });
                  }}
                />
              </div>
              <div className="calc-image">
                <img src="/assets/img/nft/solana-card.png" alt="solana-card" />
              </div>
            </div>
            <div className="calc-card">
              <div className="calc-input">
                <input
                  placeholder="0"
                  onChange={(e) => {
                    setBalance({
                      ...balance,
                      black: parseValue(e.target.value),
                    });
                  }}
                />
              </div>
              <div className="calc-image">
                <img src="/assets/img/nft/black-card.png" alt="black-card" />
              </div>
            </div>
          </div>
          <div className="calc-bottom">
            <div className="calc-community">
              <div className="calc-desc">
                Estimated profits in DAO wallet at payout
              </div>
              <input
                placeholder="0"
                onChange={(e) => {
                  setCommunityIncome(parseValue(e.target.value));
                }}
              />
            </div>
            <div className="calc-result">
              <div className="calc-desc">Your profits that month</div>
              <input placeholder="0" readOnly={true} id="personal-income" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Calculator;
