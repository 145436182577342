/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import ReactMarkdown from "react-markdown";
import qs from "qs";

import fetch from "utils/FetchInterceptor";

const Doxxed = (props) => {
  return (
    <>
      <img src="/assets/img/site/doxxed.svg" alt="Doxxed" /> Doxxed&nbsp;
    </>
  );
};
const Verified = (props) => {
  return (
    <>
      <img src="/assets/img/site/verified.svg" alt="Verified" /> Verified
    </>
  );
};

const mintDateTime = (date, time) => {
  if (date != null) {
    const month = new Date(date)
      .toLocaleString("en-US", { month: "short" })
      .toUpperCase();
    let dateSuffix = "";
    switch (parseInt(date.split("-")[2])) {
      case 1:
        dateSuffix = "ST";
        break;

      case 2:
        dateSuffix = "ND";
        break;

      case 3:
        dateSuffix = "RD";
        break;

      default:
        dateSuffix = "TH";
        break;
    }
    let datetimeString = `${month} ${parseInt(
      date.split("-")[2]
    )}${dateSuffix} — `;

    if (time != null) {
      datetimeString += time.split(":")[0] + ":" + time.split(":")[1] + " UTC";
    } else {
      datetimeString += "TBA";
    }
    return datetimeString;
  } else {
    return "TBA";
  }
};

class FeaturedItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSpotlight: null,
    };
  }

  render() {
    return (
      <>
        {this.state.activeSpotlight ? (
          <>
            <h2 className="mb-4" data-aos="fade-up" data-aos-duration="1000">
              Project spotlight
            </h2>
            <div className="row" data-aos="fade-up" data-aos-duration="1000">
              <div className="col-md-12">
                <div className="featured-item">
                  <div className="featured-img">
                    <img
                      src={this.state.activeSpotlight.image.data.attributes.url}
                      alt={this.state.activeSpotlight.name}
                    />
                    <span className="badges">
                      {this.state.activeSpotlight.doxxed ? <Doxxed /> : <></>}
                      {this.state.activeSpotlight.verified ? (
                        <Verified />
                      ) : (
                        <></>
                      )}
                    </span>
                    <span className="sol-price">
                      {this.state.activeSpotlight.price} ◎
                    </span>
                  </div>
                </div>
              </div>
              <div className="featured-content-right col-md-9 offset-md-1 d-flex justify-content-center flex-column">
                <h1>{this.state.activeSpotlight.name}</h1>
                <div className="mb-3">
                  <span className="badge badge-dark">
                    {this.state.activeSpotlight.supply} Supply
                  </span>
                  <span className="badge badge-dark">
                    {mintDateTime(
                      this.state.activeSpotlight.mintDate,
                      this.state.activeSpotlight.mintTime
                    )}
                  </span>
                </div>
                <div>
                  <ReactMarkdown>
                    {this.state.activeSpotlight.description}
                  </ReactMarkdown>
                </div>
                <div>
                  <div className="btn-group mr-3" role="group">
                    {this.state.activeSpotlight.discord ? (
                      <a
                        href={this.state.activeSpotlight.discord}
                        target="_blank"
                        className="btn btn-outline-secondary"
                      >
                        <i className="fa-brands fa-discord"></i>
                      </a>
                    ) : (
                      <></>
                    )}
                    {this.state.activeSpotlight.twitter ? (
                      <a
                        href={this.state.activeSpotlight.twitter}
                        target="_blank"
                        className="btn btn-outline-secondary"
                      >
                        <i className="fa-brands fa-twitter"></i>
                      </a>
                    ) : (
                      <></>
                    )}
                    {this.state.activeSpotlight.website ? (
                      <a
                        href={this.state.activeSpotlight.website}
                        target="_blank"
                        className="btn btn-outline-secondary"
                      >
                        <i className="fa-solid fa-link"></i>
                      </a>
                    ) : (
                      <></>
                    )}
                  </div>
                  {this.state.activeSpotlight.extraLinkUrl ? (
                    <a
                      href={this.state.activeSpotlight.extraLinkUrl}
                      target={
                        this.state.activeSpotlight.extraLinkOpenNewTab === true
                          ? "_blank"
                          : ""
                      }
                    >
                      {this.state.activeSpotlight.extraLinkTitle}
                      {this.state.activeSpotlight.extraLinkOpenNewTab ? (
                        <> &nbsp;&nbsp; ↗</>
                      ) : (
                        <></>
                      )}
                    </a>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </>
    );
  }

  componentDidMount() {
    const query = qs.stringify({
      populate: ["*", "activeSpotlight.image"],
    });
    fetch({
      url: `/active-spotlight?${query}`,
      method: "get",
    })
      .then((response) =>
        this.setState({
          activeSpotlight:
            response.data.data.attributes.activeSpotlight.data.attributes,
        })
      )
      .catch((error) => {
        this.setState({ activeSpotlight: null });
        console.error(error.message);
      });
  }

  componentWillUnmount() {}
}

export default FeaturedItem;
